import * as React from "react"

const LevelOnePyramid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={49}
    height={37}
    viewBox="0 0 49 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.772 12.9648H16.5748L9.57475 23.4648H38.7719L31.772 12.9648ZM39.7719 24.9648H32.9234V35.4648H46.7718L39.7719 24.9648ZM31.4234 35.4648V24.9648H16.9234V35.4648H31.4234ZM15.4234 35.4648V24.9648H8.57475L1.57476 35.4648H15.4234ZM22.9946 0.630841C23.5554 -0.210283 24.7914 -0.210278 25.3521 0.630842L32.7974 11.7988L48.1063 34.7623C48.7339 35.7038 48.059 36.9648 46.9276 36.9648H1.41905C0.287555 36.9648 -0.387321 35.7038 0.24031 34.7623L22.9946 0.630841Z"
      fill={props.color}
    />
  </svg>
)
export default LevelOnePyramid

import * as React from "react"

const LevelFivePyramid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={49}
    height={37}
    viewBox="0 0 49 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9615 11.4648L24.5601 1.5669L31.1587 11.4648H17.9615ZM16.9615 12.9648H32.1587L39.1587 23.4648H9.96147L16.9615 12.9648ZM31.8101 24.9648V35.4648H17.3101V24.9648H31.8101ZM15.8101 24.9648V35.4648H1.96148L8.96147 24.9648H15.8101ZM25.7388 0.630842C25.1781 -0.210278 23.9421 -0.210283 23.3813 0.630841L0.627029 34.7623C-0.000602126 35.7038 0.674274 36.9648 1.80576 36.9648H47.3144C48.4459 36.9648 49.1208 35.7038 48.4931 34.7623L25.7388 0.630842Z"
      fill={props.color}
    />
  </svg>
)
export default LevelFivePyramid

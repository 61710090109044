// types
import React from "react"
import { Level } from "../prismic-types/common"
import LevelOnePyramid from "./icons/LevelOnePyramid"
import LevelTwoPyramid from "./icons/LevelTwoPyramid"
import LevelThreePyramid from "./icons/LevelThreePyramid"
import LevelFourPyramid from "./icons/LevelFourPyramid"
import LevelFivePyramid from "./icons/LevelFivePyramid"

type IProps = {
  level?: Level
  commonProps: any
}

const PyramidProgress = ({ level, commonProps }: IProps) => {
  if (!level) return null

  if (level === 1) return <LevelOnePyramid {...commonProps} />
  if (level === 2) return <LevelTwoPyramid {...commonProps} />
  if (level === 3) return <LevelThreePyramid {...commonProps} />
  if (level === 4) return <LevelFourPyramid {...commonProps} />
  if (level === 5) return <LevelFivePyramid {...commonProps} />

  return null
}
export default PyramidProgress

import * as React from "react"

const LevelFourPyramid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={49}
    height={37}
    viewBox="0 0 49 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6148 11.4648L24.2134 1.5669L30.812 11.4648H17.6148ZM16.6148 12.9648H31.812L38.812 23.4648H9.61479L16.6148 12.9648ZM32.9634 24.9648H39.812L46.812 35.4648H32.9634V24.9648ZM15.4634 24.9648V35.4648H1.6148L8.61479 24.9648H15.4634ZM25.3921 0.630842C24.8314 -0.210278 23.5954 -0.210283 23.0347 0.630841L0.280349 34.7623C-0.347282 35.7038 0.327594 36.9648 1.45909 36.9648H46.9677C48.0992 36.9648 48.7741 35.7038 48.1465 34.7623L25.3921 0.630842Z"
      fill={props.color}
    />
  </svg>
)
export default LevelFourPyramid

import * as React from "react"

const LevelThreePyramid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={37}
    viewBox="0 0 50 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2681 11.4648L24.8667 1.5669L31.4653 11.4648H18.2681ZM17.2681 12.9648H32.4653L39.4653 23.4648H10.2681L17.2681 12.9648ZM33.6167 24.9648H40.4653L47.4653 35.4648H33.6167V24.9648ZM32.1167 24.9648V35.4648H17.6167V24.9648H32.1167ZM26.0455 0.630842C25.4847 -0.210278 24.2487 -0.210283 23.688 0.630841L0.93367 34.7623C0.306038 35.7038 0.980915 36.9648 2.11241 36.9648H47.621C48.7525 36.9648 49.4274 35.7038 48.7998 34.7623L26.0455 0.630842Z"
      fill={props.color}
    />
  </svg>
)
export default LevelThreePyramid

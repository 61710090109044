import * as React from "react"

const LevelTwoPyramid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={49}
    height={37}
    viewBox="0 0 49 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6988 0.630842C25.138 -0.210278 23.9021 -0.210283 23.3413 0.630841L0.58699 34.7623C-0.0406412 35.7038 0.634235 36.9648 1.76573 36.9648H47.2744C48.4058 36.9648 49.0807 35.7038 48.4531 34.7623L25.6988 0.630842ZM17.9214 11.4648L24.52 1.5669L31.1187 11.4648H17.9214ZM33.27 24.9648H40.1187L47.1186 35.4648H33.27V24.9648ZM31.77 24.9648V35.4648H17.2701V24.9648H31.77ZM15.7701 24.9648V35.4648H1.92144L8.92143 24.9648H15.7701Z"
      fill={props.color}
    />
  </svg>
)
export default LevelTwoPyramid
